import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import QuoteForm, { QuoteFormState } from "./QuoteForm";
import Graphic from "../Graphic/Graphic";

import UserContext from "../../store/user-context";
import Design from "../../models/design";
import Product from "../../models/product";

import styles from "./Quote.module.css";
import Opening from "../../models/opening";
import {
  OPENINGS,
  ProductCategory,
  ProductSpecialTag,
} from "../../utils/globalConstants";
import {
  ActionIcon,
  Box,
  Center,
  Checkbox,
  Container,
  Group,
  Loader,
  Pagination,
  Select,
  SelectItem,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { BiCopy, BiPurchaseTag, BiTrash } from "react-icons/bi";
import BrandLines from "../../models/brand-lines";
import AcopInput from "../UI/AcopInput";
import Item from "../../models/item";
import { QuotePart } from "./NewQuote";
import { useQuery } from "react-query";
import { getMultipleDesigns } from "../../services/services";
import {
  normalizeHorizontalCrossbars,
  normalizeVerticalCrossbars,
  recalculateModules,
  recalculateSubmodules,
} from "./ModuleHelpers";

const DEFAULT_LONG_LENGTH = 1500;
const DEFAULT_SHORT_LENGTH = 1000;
const UPDATE_TIME = 300;

type QuoteProps = {
  quoteId: number;
  parts: QuotePart[];
  opened: boolean;
  brands: BrandLines[];
  canClone: boolean;
  onRename: (quoteId: number, compact: boolean) => void;
  onClone: (quoteId: number) => void;
  onRemove: (quoteId: number) => void;
  onLoadError: (quoteId: number) => void;
  premarcs: Design[];
  mosquitos: Design[];
  modularPanels: Design[];
  extra: Product[];
  custom: Product[];
  acops?: Product[];
  initialAcops?: Item[];
  initialUnifiedOps?: {
    premarc: string;
    tapajun: boolean;
  };
  onAcopsChanged?: (quoteId: number, acops: Item[]) => void;
  onQuoteApplied: (
    quoteIndex: number,
    partIndex: number,
    formState: QuoteFormState | QuoteFormState[],
    unifiedPremarc?: string,
    unifiedTapajun?: boolean
  ) => void;
};

type QuotePartData = {
  quotePartId: number;
  design: Design;
  opening: Opening;
  form: QuoteFormState;
  metadata: {
    frameCutAngle: number;
    panelCutAngle: number;
    thinCrossbar: boolean;
  };
  normalizedCrossbars: {
    horizontal: number[];
    vertical: number[];
    mosquito: number[];
  };
  layout: { x: number; y: number };
  hasCrossbar?: boolean;
  hasRevest?: boolean;
  hasTapajun?: boolean;
  hasTapacin?: boolean;
};

export type QuoteFormActionType =
  | { type: "init"; partId?: number; value: QuotePartData[] }
  | { type: "all"; partId?: number; value: QuoteFormState }
  | {
      type:
        | "width"
        | "height"
        | "x"
        | "y"
        | "count"
        | "tapacin"
        | "side"
        | "crossCut"
        | "crossCutLength";
      partId?: number;
      value: number;
    }
  | {
      type: "color" | "mosquito" | "premarc" | "guide";
      partId?: number;
      value: string;
    }
  | { type: "tapajun"; partId?: number; value: boolean }
  | {
      type: "modules";
      partId?: number;
      value: string;
      index: number;
    }
  | {
      type: "submodules";
      partId?: number;
      value: string;
      index: number;
      subIndex: number;
    }
  | {
      type: "crossModeH" | "crossModeV" | "crossModeMos";
      partId?: number;
      value: string;
    }
  | {
      type: "crossH" | "crossV" | "crossMos";
      partId?: number;
      value: number | number[];
    }
  | {
      type: "normalCross";
      partId?: number;
      value: { horizontal: number[]; vertical: number[]; mosquito: number[] };
    }
  | {
      type: "modularPanel";
      partId?: number;
      value: boolean;
    }
  | {
      type: "modularDesign";
      partId?: number;
      value: string;
    }
  | {
      type:
        | "modularSide"
        | "modularLength"
        | "modularOpenSide"
        | "monorailSide"
        | "monorailLength";
      partId?: number;
      value: number;
    };

const reducer = (state: QuotePartData[], action: QuoteFormActionType) => {
  if (action.type === "init") {
    return action.value;
  } else {
    if (!action.partId) action.partId = 0;
    return state.map((part) => {
      if (part.quotePartId === action.partId) {
        switch (action.type) {
          case "all":
            part.form = action.value;
            break;
          case "width":
            part.form.width = action.value;
            break;
          case "height":
            part.form.height = action.value;
            break;
          case "mosquito":
            part.form.mosquito = action.value;
            break;
          case "premarc":
            part.form.premarc = action.value;
            break;
          case "tapajun":
            part.form.tapajun = action.value;
            break;
          case "guide":
            part.form.guide = action.value;
            break;
          case "tapacin":
            part.form.tapacin = action.value;
            break;
          case "side":
            part.form.side = action.value;
            break;
          case "x":
            part.layout.x = action.value;
            break;
          case "y":
            part.layout.y = action.value;
            break;
          case "crossModeH":
            part.form.crossModeH = action.value;
            break;
          case "crossModeV":
            part.form.crossModeV = action.value;
            break;
          case "crossModeMos":
            part.form.crossModeMos = action.value;
            break;
          case "crossH":
            part.form.crossH = action.value;
            part.form.modules = recalculateModules(
              part.form.modules,
              action.value
            );
            part.normalizedCrossbars = {
              ...part.normalizedCrossbars,
              horizontal: normalizeHorizontalCrossbars(
                action.value,
                part.form.height
              ),
            };
            if (part.form.crossH) {
              if (part.form.crossCut !== 0) {
                const maxMods =
                  typeof part.form.crossH === "number"
                    ? part.form.crossH
                    : part.form.crossH.length;
                part.form.crossCut =
                  Math.sign(part.form.crossCut) *
                  Math.min(Math.abs(part.form.crossCut), maxMods);
              }
            } else {
              part.form.crossCut = 0;
            }
            break;
          case "crossV":
            part.form.crossV = action.value;
            if (part.opening.submodules) {
              part.form.modules = recalculateSubmodules(
                part.form.modules,
                action.value,
                part.form.crossCut
              );
            }
            part.normalizedCrossbars = {
              ...part.normalizedCrossbars,
              vertical: normalizeVerticalCrossbars(
                action.value,
                part.form.width,
                part.design.pan
              ),
            };
            break;
          case "crossCut":
            let baseValue = Math.abs(part.form.crossCut);
            if (action.value !== 0 && baseValue === 0) {
              baseValue = 1;
            }
            const newCut = baseValue * +action.value;
            part.form.crossCut = newCut;
            if (part.opening.submodules) {
              part.form.modules = recalculateSubmodules(
                part.form.modules,
                action.value,
                part.form.crossCut
              );
            }
            break;
          case "crossCutLength":
            const newCutLength =
              Math.sign(part.form.crossCut) * Math.max(1, +action.value);
            part.form.crossCut = newCutLength;
            if (part.opening.submodules) {
              part.form.modules = recalculateSubmodules(
                part.form.modules,
                action.value,
                part.form.crossCut
              );
            }
            break;
          case "crossMos":
            part.form.crossMos = action.value;
            part.normalizedCrossbars = {
              ...part.normalizedCrossbars,
              mosquito: normalizeHorizontalCrossbars(
                action.value,
                part.form.height
              ),
            };
            break;
          case "normalCross":
            part.normalizedCrossbars = action.value;
            break;
          case "modules":
            const updatedModules: (string | string[])[] = part.form.modules;
            updatedModules[action.index] = action.value;
            part.form.modules = updatedModules;
            break;
          case "submodules":
            const updatedModls: (string | string[])[] = part.form.modules;
            const updatedSubmodls: string | string[] =
              updatedModls[action.index];
            if (Array.isArray(updatedSubmodls)) {
              updatedSubmodls[action.subIndex] = action.value;
              part.form.modules = updatedModls;
            }
            break;
          case "modularPanel":
            part.form.modularPanel = action.value;
            if (action.value === true) {
              part.form.crossModeH = "";
              part.form.crossModeV = "";
              part.form.crossH = undefined;
              part.form.crossV = undefined;
              part.normalizedCrossbars.horizontal = [];
              part.normalizedCrossbars.vertical = [];
              if (
                part.form.modules.length > 0 &&
                part.form.modules[0] === "rev"
              ) {
                part.form.modules = [""];
              } else {
                part.form.modules = part.form.modules.slice(0, 1);
              }
            } else {
              part.form.modularDesign = "";
              part.form.mosquito = "";
            }
            break;
          case "modularDesign":
            part.form.modularDesign = action.value;
            break;
          case "modularSide":
            part.form.modularSide = action.value;
            break;
          case "modularLength":
            part.form.modularLength = action.value;
            break;
          case "modularOpenSide":
            part.form.modularOpenSide = action.value;
            break;
          case "monorailSide":
            part.form.monorailSide = action.value;
            break;
          case "monorailLength":
            part.form.monorailLength = action.value;
            break;
          default:
            break;
        }
      }
      // Count and color update all parts together
      if (action.type === "count") {
        part.form.count = action.value;
      }
      if (action.type === "color") {
        part.form.color = action.value;
      }
      return part;
    });
  }
};

const Quote = (props: QuoteProps) => {
  const userCtx = useContext(UserContext);

  const firstTimeApplied = useRef(false);

  const {
    onQuoteApplied,
    onAcopsChanged,
    // designLayout,
    opened,
    quoteId,
    brands = [],
    premarcs = [],
    mosquitos = [],
    modularPanels = [],
    extra = [],
    custom = [],
  } = props;

  const [quoteState, dispatch] = useReducer(reducer, []);
  const [partCount, setPartCount] = useState(1);
  const [enableActions, setEnableActions] = useState(false);

  const [quoteBrand, setQuoteBrand] = useState("");
  const [quoteLine, setQuoteLine] = useState("");
  const [brandReadable, setBrandReadable] = useState("");
  const [lineReadable, setLineReadable] = useState("");
  const [partPage, setPartPage] = useState(0);
  const [pvc, setPvc] = useState(false);

  const [colorList, setColorList] = useState<SelectItem[]>([]);
  const [glassList, setGlassList] = useState<SelectItem[]>([]);
  const [premarcList, setPremarcList] = useState<SelectItem[]>([]);
  const [mosquitoList, setMosquitoList] = useState<SelectItem[]>([]);
  const [guideList, setGuideList] = useState<SelectItem[]>([]);
  const [modularPanelList, setModularPanelList] = useState<SelectItem[]>([]);

  const [unifiedPremarc, setUnifiedPremarc] = useState<string>(
    props.initialUnifiedOps?.premarc || ""
  );
  const [unifiedTapajun, setUnifiedTapajun] = useState<boolean>(
    props.initialUnifiedOps?.tapajun || false
  );

  const [quoteAcops, setQuoteAcops] = useState<Item[]>([]);

  const nextAcopId = useRef(0);

  const loadDesign = (designs: Design[]) => {
    if (designs && designs.length > 0) {
      // Use first design for brand and line (whole quote should use one brand and line)
      const brand = brands.find((br) => br.code === designs[0].brand);
      const line = brand.lines.find((lin) => lin.code === designs[0].line);
      const isPvc = brand.pvc;
      setQuoteBrand(brand.code);
      setQuoteLine(line.code);
      setBrandReadable(brand.name);
      setLineReadable(line.name);
      setPvc(isPvc);

      const fullQuoteData: QuotePartData[] = [];
      const isUnified = props.parts.length > 1;
      let partIndex = 0;
      props.parts.forEach((part, index) => {
        const designData = designs.find((d) => d._id === part.designId);
        if (designData) {
          const openingData = OPENINGS.find(
            (op) => op.code === designData.open
          );
          // Set angles metadata (for graphic)
          const itemsMar: Item[] = [];
          const itemsPan: Item[] = [];
          designData.items
            .filter((it) => it.ang && it.ang !== 45)
            .forEach((it) => {
              const prod = designData.products.find((p) => p._id === it.prodId);
              if (prod && prod.spc !== ProductSpecialTag.INVISIBILE) {
                if (prod.cat === ProductCategory.FRAME) {
                  itemsMar.push(it);
                } else if (prod.cat === ProductCategory.PANEL) {
                  itemsPan.push(it);
                }
              }
            });
          const frameCutAngle = itemsMar.some((p) => p.ang === 4590)
            ? 4590
            : itemsMar.some((p) => p.ori === "h") &&
              itemsMar.some((p) => p.ori === "v")
            ? 90
            : 45;
          const panelCutAngle = itemsPan.some((p) => p.ang === 4590)
            ? 4590
            : itemsPan.some((p) => p.ori === "h") &&
              itemsPan.some((p) => p.ori === "v")
            ? 90
            : 45;

          const hasCrossbar = Boolean(designData.crossId);
          const thinCrossbar =
            hasCrossbar &&
            designData.products.find((p) => p._id === designData.crossId)
              .spc2 === ProductSpecialTag.THIN;
          const hasRevest = Boolean(designData.revId);
          const hasTapajun = designData.products.some(
            (p) => p.cat === ProductCategory.TAPAJUN
          );
          const hasTapacin = designData.products.some(
            (p) => p.spc === ProductSpecialTag.TAPACIN
          );

          const formInitialValues: QuoteFormState = props.parts[index].form
            ? props.parts[index].form
            : {
                partId: partIndex,
                width: isUnified
                  ? part.layout.w
                  : openingData.defSize
                  ? openingData.defSize[0] *
                    (openingData.panelOri !== "v" ? designData.pan : 1)
                  : openingData.tallerThanWider
                  ? DEFAULT_SHORT_LENGTH
                  : DEFAULT_LONG_LENGTH,
                height: isUnified
                  ? part.layout.h
                  : openingData.defSize
                  ? openingData.defSize[1]
                  : openingData.tallerThanWider
                  ? DEFAULT_LONG_LENGTH
                  : DEFAULT_SHORT_LENGTH,
                count: 1,
                color: "",
                mosquito: openingData.code === "mosq" ? "self" : "",
                premarc: openingData.code === "prem" ? "self" : "",
                tapacin: 0,
                tapajun: false,
                side: 1,
                guide: "",
                modules: [""],
                crossModeH: "",
                crossModeV: "",
                crossModeMos: "",
                crossH: undefined,
                crossV: undefined,
                crossMos: undefined,
                crossCut: 0,
                modularPanel: false,
                modularDesign: "",
                modularSide: -1,
                modularLength: 500,
                modularOpenSide: -1,
                monorailSide: designData.pan === 1 ? designData.pan * -1 : 2,
                monorailLength: 500,
              };
          const initialNormalizedCrossbars: {
            horizontal: number[];
            vertical: number[];
            mosquito: number[];
          } = props.parts[index].form
            ? {
                horizontal: props.parts[index].form.crossH
                  ? normalizeHorizontalCrossbars(
                      props.parts[index].form.crossH,
                      props.parts[index].form.height
                    )
                  : [],
                vertical: props.parts[index].form.crossV
                  ? normalizeVerticalCrossbars(
                      props.parts[index].form.crossV,
                      props.parts[index].form.width,
                      designData.pan
                    )
                  : [],
                mosquito: props.parts[index].form.crossMos
                  ? normalizeHorizontalCrossbars(
                      props.parts[index].form.crossMos,
                      props.parts[index].form.height
                    )
                  : [],
              }
            : {
                horizontal: [],
                vertical: [],
                mosquito: [],
              };

          // Push quote part to row
          fullQuoteData.push({
            quotePartId: partIndex,
            design: designData,
            opening: openingData,
            form: formInitialValues,
            layout: { x: part.layout?.x || 0, y: part.layout?.y || 0 },
            metadata: { frameCutAngle, panelCutAngle, thinCrossbar },
            normalizedCrossbars: initialNormalizedCrossbars,
            hasCrossbar,
            hasRevest,
            hasTapajun,
            hasTapacin,
          });

          partIndex++;
        }
      });

      if (props.initialAcops) {
        let acopIndex = 0;
        const indexedAcops = props.initialAcops.map((a) => ({
          ...a,
          itemId: acopIndex++,
        }));
        nextAcopId.current = acopIndex;
        setQuoteAcops(indexedAcops);
      }

      dispatch({ type: "init", value: fullQuoteData });
      setPartCount(fullQuoteData.length);
      setEnableActions(true);
    }
  };

  const designIds = props.parts.map((design) => design.designId);
  const { status } = useQuery(
    ["design-multi", designIds],
    () => getMultipleDesigns(designIds, userCtx.token),
    {
      enabled: opened,
      onSuccess: (data) => {
        if (!firstTimeApplied.current) {
          if (data.length > 0) loadDesign(data);
          else {
            props.onLoadError(props.quoteId);
          }
        }
      },
      onError: () => {
        props.onLoadError(props.quoteId);
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const currentQuotePart = quoteState.find(
    (part) => part.quotePartId === partPage
  );

  const renameClickHandler = () => {
    props.onRename(props.quoteId, false);
  };

  const cloneClickHandler = () => {
    props.onClone(props.quoteId);
  };

  const removeClickHandler = () => {
    props.onRemove(props.quoteId);
  };

  const formChangeHandler = (action: QuoteFormActionType) => {
    dispatch(action);
  };

  const applyFormHandler = useCallback(
    (
      partId: number,
      formValues: QuoteFormState | QuoteFormState[],
      unifPremarc = "",
      unifTapajun = false
    ) => {
      if (!formValues) {
        return;
      }
      // Send changes to parent (QuotePage)
      onQuoteApplied(quoteId, partId, formValues, unifPremarc, unifTapajun);
    },
    [onQuoteApplied, quoteId]
  );

  const unifiedPremarcChangeHandler = (value: string) => {
    setUnifiedPremarc(value);
  };

  const unifiedTapajunChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUnifiedTapajun(e.target.checked);
  };

  const acopAddedHandler = (item: Item) => {
    const product = props.acops.find((acop) => acop._id === item.prodId);
    if (product) {
      item.itemId = nextAcopId.current;
      item.code = product.code;
      item.name = product.name;
      nextAcopId.current += 1;
      setQuoteAcops((prev) => {
        const newAcops = prev.concat(item);
        onAcopsChanged(quoteId, newAcops);
        return newAcops;
      });
    }
  };

  const acopRemovedHandler = (acopId: number) => {
    setQuoteAcops((prev) => {
      const newAcops = prev.filter((i) => i.itemId !== acopId);
      onAcopsChanged(quoteId, newAcops);
      return newAcops;
    });
  };

  useEffect(() => {
    if (currentQuotePart) {
      if (!firstTimeApplied.current) {
        firstTimeApplied.current = true;
        const forms = quoteState.map((part) => part.form);
        applyFormHandler(0, forms, unifiedPremarc, unifiedTapajun);
      } else {
        const timeout = setTimeout(() => {
          applyFormHandler(
            partPage,
            currentQuotePart.form,
            unifiedPremarc,
            unifiedTapajun
          );
        }, UPDATE_TIME);
        return () => clearTimeout(timeout);
      }
    }
  }, [
    applyFormHandler,
    partPage,
    quoteState,
    currentQuotePart,
    unifiedPremarc,
    unifiedTapajun,
  ]);

  useEffect(() => {
    const customColors = custom
      .filter(
        (p) =>
          [ProductCategory.PAINT, ProductCategory.ANOD].includes(
            p.cat as ProductCategory
          ) &&
          p.pri &&
          (pvc ? p.spc === "pvc" : !p.spc)
      )
      .sort((a, b) => a.ord - b.ord)
      .map((col) => {
        return { value: col._id, label: col.name };
      });
    const colors = pvc
      ? customColors
      : [{ value: "", label: "Sin pintar" }].concat(customColors);
    const glasses = [{ value: "", label: "No" }].concat(
      custom
        .filter(
          (p) =>
            [
              ProductCategory.GLASS,
              ProductCategory.POLIC,
              ProductCategory.PNL,
            ].includes(p.cat as ProductCategory) && p.pri
        )
        .map((col) => {
          return { value: col._id, label: col.name };
        })
    );
    setColorList(colors);
    setGlassList(glasses);
    if (pvc) {
      dispatch({ type: "color", value: colors[0].value });
    }
  }, [custom, pvc]);

  useEffect(() => {
    const filteredPremarcs =
      premarcs && quoteLine
        ? [{ value: "", label: "No" }].concat(
            premarcs
              .filter((p) => p.line === quoteLine)
              .map((prem) => {
                return {
                  value: prem._id,
                  label: prem.name,
                };
              })
          )
        : [{ value: "", label: "No" }];
    const filteredMosquitos =
      mosquitos && quoteLine
        ? [{ value: "", label: "No" }].concat(
            mosquitos
              .filter((m) => m.line === quoteLine)
              .map((mosq) => {
                return {
                  value: mosq._id,
                  label: mosq.name,
                };
              })
          )
        : [{ value: "", label: "No" }];
    const filteredGuides =
      extra && quoteLine
        ? [{ value: "", label: "No" }].concat(
            extra
              .filter(
                (p) => p.cat === ProductCategory.GUIDE && p.lin === quoteLine
              )
              .map((gui) => {
                return {
                  value: gui._id,
                  label: gui.name,
                };
              })
          )
        : [{ value: "", label: "No" }];
    const filteredModularPanels =
      modularPanels && quoteLine
        ? modularPanels
            .filter((d) => d.line === quoteLine)
            .map((des) => {
              return {
                value: des._id,
                label: des.name,
              };
            })
        : [];

    setPremarcList(filteredPremarcs);
    setMosquitoList(filteredMosquitos);
    setGuideList(filteredGuides);
    setModularPanelList(filteredModularPanels);
  }, [premarcs, mosquitos, modularPanels, extra, quoteLine]);

  const replaceModuleCode = (mod: string) => {
    if (!mod || mod === ProductCategory.REVEST) {
      return mod;
    } else {
      const prod = custom.find((p) => p._id === mod);
      return prod.col ? prod.col : prod.cat;
    }
  };

  const calculateGraphicModules = (part: QuotePartData) => {
    return part.form
      ? part.form.modules.map((mod) => {
          if (Array.isArray(mod)) {
            return mod.map((m) => replaceModuleCode(m));
          } else return replaceModuleCode(mod);
        })
      : [];
  };

  const mosquitoHasCrossbar =
    currentQuotePart &&
    currentQuotePart.form.mosquito &&
    Boolean(
      mosquitos.find((m) => m._id === currentQuotePart.form.mosquito)?.crossId
    );
  const modularPanelOpenType = currentQuotePart?.form.modularPanel
    ? modularPanels.find((d) => d._id === quoteState[0].form.modularDesign)
        ?.open || ""
    : "";
  const paintColorHex = currentQuotePart?.form
    ? custom?.find((xp) => xp._id === currentQuotePart.form.color)?.col
    : "";

  return (
    <Container fluid sx={{ position: "relative" }}>
      <Box sx={{ position: "absolute", top: "0", right: "15px" }}>
        {enableActions && (
          <Stack spacing="lg">
            <Tooltip
              label="Código y orden"
              position="left"
              transitionProps={{ transition: "fade" }}
              withArrow
            >
              <ActionIcon
                variant="transparent"
                color="brand"
                radius="xl"
                onClick={renameClickHandler}
                disabled={!props.canClone}
              >
                <BiPurchaseTag size={28} />
              </ActionIcon>
            </Tooltip>
            <Tooltip
              label="Duplicar"
              position="left"
              transitionProps={{ transition: "fade" }}
              withArrow
            >
              <ActionIcon
                variant="transparent"
                color="blue"
                radius="xl"
                onClick={cloneClickHandler}
                disabled={!props.canClone}
              >
                <BiCopy size={28} />
              </ActionIcon>
            </Tooltip>
            <Tooltip
              label="Eliminar"
              position="left"
              transitionProps={{ transition: "fade" }}
              withArrow
            >
              <ActionIcon
                variant="transparent"
                color="red"
                radius="xl"
                onClick={removeClickHandler}
              >
                <BiTrash size={28} />
              </ActionIcon>
            </Tooltip>
          </Stack>
        )}
      </Box>
      {quoteState.length === 0 && (
        <Center mt={40}>
          <Stack align="center">
            <Text size="lg">Cargando diseño...</Text>
            <Loader size="lg" variant="dots" />
          </Stack>
        </Center>
      )}
      <div className={styles.topSection}>
        {quoteState.length > 0 && (
          <Stack align="center">
            {partCount > 1 && (
              <Pagination
                total={partCount}
                value={partPage + 1}
                onChange={(page: number) => setPartPage(page - 1)}
              />
            )}
            <QuoteForm
              className={styles.divForm}
              quotePartId={partPage}
              formValues={currentQuotePart.form}
              quoteId={props.quoteId}
              brandDisplay={brandReadable}
              lineDisplay={lineReadable}
              opening={currentQuotePart.opening}
              colors={colorList}
              glasses={glassList}
              premarcList={premarcList}
              mosquitoList={mosquitoList}
              guideList={guideList}
              modularPanelList={modularPanelList}
              panels={currentQuotePart.design.pan}
              hasTapajun={currentQuotePart.hasTapajun}
              hasTapacin={currentQuotePart.hasTapacin}
              hasCrossbar={currentQuotePart.hasCrossbar}
              hasRevest={currentQuotePart.hasRevest}
              mosqHasCrossbar={mosquitoHasCrossbar}
              pvc={pvc}
              loading={status === "loading"}
              unified={partCount > 1}
              onChange={formChangeHandler}
            />
          </Stack>
        )}
        <Center className={styles.divGraphic}>
          {quoteState.length > 0 && (
            <Stack>
              <Graphic
                unified={partCount > 1}
                currentId={currentQuotePart.quotePartId}
                data={quoteState.map((part) => {
                  return {
                    partId: part.quotePartId,
                    realWidth: part.form.width ?? DEFAULT_LONG_LENGTH,
                    realHeight: part.form.height ?? DEFAULT_LONG_LENGTH,
                    xPosition: part.layout?.x || 0,
                    yPosition: part.layout?.y || 0,
                    color: paintColorHex,
                    modules: calculateGraphicModules(part),
                    frameAng: part.metadata.frameCutAngle,
                    panelAng: part.metadata.panelCutAngle,
                    openingType: part.design.open,
                    mosquito: Boolean(part.form.mosquito),
                    tapajun: part.form.tapajun,
                    guide: Boolean(part.form.guide),
                    side: part.form.side ?? 0,
                    panels: part.design.pan,
                    threeSidedTapajun: part.opening.threeSidedTapajun,
                    crossbarsH: part.normalizedCrossbars.horizontal,
                    crossbarsV: part.normalizedCrossbars.vertical,
                    crossbarsMos: part.normalizedCrossbars.mosquito,
                    crossCut: part.form.crossCut ?? 0,
                    thinCrossbar: part.metadata.thinCrossbar,
                    tapacin: part.form.tapacin ?? 0,
                    modularPanel: part.form.modularPanel
                      ? {
                          openingType: modularPanelOpenType,
                          side: part.form.modularSide,
                          length: part.form.modularLength,
                          openSide: part.form.modularOpenSide,
                        }
                      : null,
                    monorail: part.opening.monorail
                      ? {
                          side: part.form.monorailSide,
                          length: part.form.monorailLength,
                        }
                      : null,
                  };
                })}
              />
            </Stack>
          )}
        </Center>
      </div>

      {partCount > 1 && (
        <Stack mt="md">
          <Group my={4} align="flex-start">
            <Text mt={8} mr="xl">
              Opciones unificada
            </Text>
            <Stack>
              <Group>
                <Text>Premarco</Text>
                <Select
                  data={premarcList}
                  value={unifiedPremarc}
                  onChange={unifiedPremarcChangeHandler}
                  disabled={premarcList.length <= 1}
                />
              </Group>
              <Checkbox
                label="Tapajuntas"
                checked={unifiedTapajun}
                onChange={unifiedTapajunChangeHandler}
                disabled={quoteState.length === 0 || !quoteState[0].hasTapajun}
              />
            </Stack>
          </Group>
          <Box
            p="md"
            sx={(theme) => ({
              backgroundColor: theme.colors.gray[2],
              padding: 8,
              borderRadius: 4,
            })}
          >
            <AcopInput
              products={props.acops ?? []}
              table={quoteAcops}
              brand={quoteBrand}
              onSubmitItem={acopAddedHandler}
              onRemoveItem={acopRemovedHandler}
            />
          </Box>
        </Stack>
      )}
    </Container>
  );
};

export default React.memo(Quote);

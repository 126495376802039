import { useEffect, useRef } from "react";
import * as Helpers from "./GraphicHelpers";
import { Ironwork } from "./GraphicHelpers";

import mosqTexture from "../../assets/mosq_tx.png";

import styles from "./Graphic.module.css";
import { MAX_MEASURE, MIN_MEASURE } from "../../utils/globalConstants";

const LIMIT_LENGTH = 1000;
const FRAME_REAL_WIDTH = 50;
const DEFAULT_SCALE = 0.3;
const SIDE_TEXT_SIZE: [number, number] = [30, 30];
const NO_COLOR = "#bdbdbd";

export type SingleGraphicProps = {
  partId: number;
  openingType: string;
  realWidth: number;
  realHeight: number;
  xPosition: number;
  yPosition: number;
  panels: number;
  modules: (string | string[])[];
  color: string;
  mosquito: boolean;
  tapajun: boolean;
  frameAng: number;
  panelAng: number;
  guide: boolean;
  tapacin?: number;
  threeSidedTapajun?: boolean;
  side?: number;
  crossbarsH?: number[];
  crossbarsV?: number[];
  crossbarsMos?: number[];
  crossCut?: number;
  thinCrossbar?: boolean;
  modularPanel?: {
    openingType: string;
    side: number;
    length: number;
    openSide?: number;
  };
  monorail?: {
    side: number;
    length: number;
  };
};

type GraphicProps = {
  className?: string;
  data: SingleGraphicProps[];
  currentId: number;
  unified: boolean;
};

const clampValue = (value: number, min: number, max: number) => {
  return Math.max(min, Math.min(value, max));
};

const Graphic = (props: GraphicProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const mosPatternRef = useRef<HTMLImageElement>(null);

  // =================================== WINDOW TYPES
  const drawCorrediza = (
    ctx: CanvasRenderingContext2D,
    ops: Helpers.GraphicOptions
  ) => {
    // Mosquito
    if (ops.mosquito)
      Helpers.drawMosquito(ctx, ops, mosPatternRef, ops.w / ops.panels, ops.h);

    // Modules
    Helpers.drawModules(ctx, ops);

    const panelWidth =
      (ops.w - ops.fr * 2) / ops.panels + ops.fr * (1 - 1 / ops.panels);
    const panelHeight = ops.h - ops.fr * 2;
    // Panels
    for (let p = 0; p < ops.panels; p++) {
      const x = p * panelWidth - p * ops.fr;
      // Crossbars
      if (ops.crossbars) {
        Helpers.drawCrossbars(ctx, ops, x + ops.fr, panelWidth, ops.h);
      }
      // Corr special options
      if (ops.corrSpc && p === 1)
        Helpers.drawRevest(
          ctx,
          ops,
          ops.w * 0.5,
          ops.h - ops.fr * 2,
          panelWidth,
          panelHeight
        );
      // Panel frame
      Helpers.drawFrame(
        ctx,
        x + ops.fr,
        ops.fr,
        panelWidth,
        panelHeight,
        ops.fr,
        ops.color,
        ops.panelAng
      );
      // Arrows
      if (ops.panels !== 3) {
        if (ops.corrSpc !== "coab" || p !== 1) {
          const arrowRot = p % 2 === 0 ? 0 : 2;
          Helpers.drawArrow(
            ctx,
            x + panelWidth * 0.5 + p * (ops.fr - 2),
            ops.h * 0.5,
            ops.scale,
            arrowRot
          );
        }
      } else {
        const arrowRot = p < 1 ? 0 : 2;
        Helpers.drawArrow(
          ctx,
          x + panelWidth * 0.5 + p * (ops.fr - 2),
          ops.h * 0.5,
          ops.scale,
          arrowRot
        );
        if (p === 1 && ops.panels === 3) {
          Helpers.drawArrow(
            ctx,
            x + panelWidth * 0.5 + p * (ops.fr - 2),
            ops.h * 0.5,
            ops.scale,
            0
          );
        }
      }
    }
    // Dashed lines on 'coab' opening
    if (ops.corrSpc === "coab")
      Helpers.drawHLines(
        ctx,
        panelWidth + ops.fr,
        ops.fr,
        panelWidth,
        ops.h - ops.fr * 2,
        ops.fr,
        1
      );
    // Guides
    if (ops.guide) Helpers.drawGuides(ctx, ops, ops.w);
    // Frame
    Helpers.drawFrame(ctx, 0, 0, ops.w, ops.h, ops.fr, ops.color, ops.frameAng);
  };

  const drawModularOpening = (
    ctx: CanvasRenderingContext2D,
    ops: Helpers.GraphicOptions,
    leftSide: boolean
  ) => {
    const modularOps: Helpers.GraphicOptions = {
      w: ops.modularPanel.length + ops.fr * 0.5,
      h: ops.h,
      fr: ops.fr,
      color: ops.color,
      modules: [],
      crossbars: [[], [], []],
      mosquito: false,
      tapajun: false,
      guide: false,
      panels: 1,
      frameAng: 45,
      panelAng: 45,
      scale: ops.scale,
      side:
        ops.modularPanel.side === 2 && !leftSide
          ? -ops.modularPanel.openSide
          : ops.modularPanel.openSide,
    };
    ctx.save();
    ctx.translate(
      leftSide ? 0 : ops.w - ops.modularPanel.length - ops.fr * 0.5,
      0
    );
    switch (ops.modularPanel.openingType) {
      case "band":
        drawVentiluzOrBanderola(ctx, modularOps, false, true);
        break;
      case "vluz":
        drawVentiluzOrBanderola(ctx, modularOps, true, true);
        break;
      case "abri":
        drawDeAbrir(ctx, modularOps, true);
        break;
      case "osci":
        drawOscilobatiente(ctx, modularOps, true);
        break;
      case "desp":
        drawDesplazable(ctx, modularOps);
        break;
      default:
        break;
    }
    ctx.restore();
  };

  const drawPanoFijo = (
    ctx: CanvasRenderingContext2D,
    ops: Helpers.GraphicOptions
  ) => {
    Helpers.drawModules(ctx, ops);
    if (ops.crossbars) Helpers.drawCrossbars(ctx, ops, 0, ops.w, ops.h);
    if (ops.modularPanel) {
      if (ops.modularPanel.side < 0 || ops.modularPanel.side > 1) {
        if (ops.mosquito)
          Helpers.drawMosquito(
            ctx,
            ops,
            mosPatternRef,
            ops.modularPanel.length,
            ops.h
          );
        drawModularOpening(ctx, ops, true);
      }
      if (ops.modularPanel.side > 0) {
        if (ops.mosquito)
          Helpers.drawMosquito(
            ctx,
            ops,
            mosPatternRef,
            ops.modularPanel.length,
            ops.h,
            0,
            ops.w - ops.modularPanel.length
          );
        drawModularOpening(ctx, ops, false);
      }
      Helpers.drawModularCrossbars(ctx, ops);
    }
    // Guides
    if (ops.guide) Helpers.drawGuides(ctx, ops, ops.w);
    // Frame
    Helpers.drawFrame(ctx, 0, 0, ops.w, ops.h, ops.fr, ops.color, ops.frameAng);
  };

  const drawVentiluzOrBanderola = (
    ctx: CanvasRenderingContext2D,
    ops: Helpers.GraphicOptions,
    opensUpwards: boolean,
    noFrame = false
  ) => {
    // Mosquito
    if (ops.mosquito)
      Helpers.drawMosquito(ctx, ops, mosPatternRef, ops.w / ops.panels, ops.h);
    // Modules
    Helpers.drawModules(ctx, ops);
    // Crossbars
    if (ops.crossbars) Helpers.drawCrossbars(ctx, ops, 0, ops.w, ops.h);
    // Guides
    if (ops.guide) Helpers.drawGuides(ctx, ops, ops.w);
    // Panel
    Helpers.drawFrame(
      ctx,
      ops.fr,
      ops.fr,
      ops.w - ops.fr * 2,
      ops.h - ops.fr * 2,
      ops.fr,
      ops.color,
      ops.panelAng,
      true,
      opensUpwards
        ? [Ironwork.HandleHorizontalLong]
        : [Ironwork.HandleHorizontalShort, Ironwork.HingesHorizontal],
      !opensUpwards,
      ops.scale
    );
    // Frame
    if (!noFrame)
      Helpers.drawFrame(
        ctx,
        0,
        0,
        ops.w,
        ops.h,
        ops.fr,
        ops.color,
        ops.frameAng
      );
    // Dashed lines
    Helpers.drawVLines(
      ctx,
      ops.fr,
      ops.fr,
      ops.w - ops.fr * 2,
      ops.h - ops.fr * 2,
      ops.fr,
      opensUpwards ? -1 : 1
    );
  };

  const drawDesplazable = (
    ctx: CanvasRenderingContext2D,
    ops: Helpers.GraphicOptions
  ) => {
    // Mosquito
    if (ops.mosquito)
      Helpers.drawMosquito(ctx, ops, mosPatternRef, ops.w / ops.panels, ops.h);
    // Modules
    Helpers.drawModules(ctx, ops);
    // Crossbars
    if (ops.crossbars) Helpers.drawCrossbars(ctx, ops, 0, ops.w, ops.h);
    // Guides
    if (ops.guide) Helpers.drawGuides(ctx, ops, ops.w);
    // Panel
    Helpers.drawFrame(
      ctx,
      ops.fr,
      ops.fr,
      ops.w - ops.fr * 2,
      ops.h - ops.fr * 2,
      ops.fr,
      ops.color,
      ops.panelAng,
      true,
      [Ironwork.HandleHorizontal],
      false,
      ops.scale
    );
    // Frame
    Helpers.drawFrame(ctx, 0, 0, ops.w, ops.h, ops.fr, ops.color, ops.frameAng);
    // Dashed lines
    Helpers.drawDashedLines(
      ctx,
      ops.w * 0.5,
      ops.h - ops.fr,
      ops.fr,
      ops.h * 0.25,
      ops.w - ops.fr,
      ops.h * 0.25
    );
    Helpers.drawDashedLines(
      ctx,
      ops.w * 0.5,
      ops.fr,
      ops.fr,
      ops.h * 0.25,
      ops.w - ops.fr,
      ops.h * 0.25
    );
  };

  const drawOscilobatiente = (
    ctx: CanvasRenderingContext2D,
    ops: Helpers.GraphicOptions,
    noFrame = false
  ) => {
    // Mosquito
    if (ops.mosquito)
      Helpers.drawMosquito(ctx, ops, mosPatternRef, ops.w / ops.panels, ops.h);
    // Modules
    Helpers.drawModules(ctx, ops);
    // Crossbars
    if (ops.crossbars) Helpers.drawCrossbars(ctx, ops, 0, ops.w, ops.h);
    // Guides
    if (ops.guide) Helpers.drawGuides(ctx, ops, ops.w);
    // Panels
    const panelWidth = (ops.w - ops.fr * 2) / ops.panels;
    const ironworks = [Ironwork.HandleVertical, Ironwork.HingesVerticalCorners];
    for (let p = 0; p < ops.panels; p++) {
      Helpers.drawFrame(
        ctx,
        ops.fr + p * panelWidth,
        ops.fr,
        panelWidth,
        ops.h - ops.fr * 2,
        ops.fr,
        ops.color,
        ops.panelAng,
        true,
        ops.panels === 1
          ? ironworks
          : Math.max(0, ops.side) === p
          ? ironworks
          : [],
        ops.side === 1,
        ops.scale
      );
    }
    // Frame
    if (!noFrame)
      Helpers.drawFrame(
        ctx,
        0,
        0,
        ops.w,
        ops.h,
        ops.fr,
        ops.color,
        ops.frameAng
      );
    // Dashed lines
    if (ops.panels === 1) {
      Helpers.drawHLines(
        ctx,
        ops.fr,
        ops.fr,
        panelWidth,
        ops.h - ops.fr * 2,
        ops.fr,
        ops.side
      );
      Helpers.drawVLines(
        ctx,
        ops.fr,
        ops.fr,
        panelWidth,
        ops.h - ops.fr * 2,
        ops.fr,
        1
      );
    } else if (ops.panels === 2) {
      for (let p = 0; p < ops.panels; p++) {
        Helpers.drawHLines(
          ctx,
          panelWidth * p + ops.fr,
          ops.fr,
          panelWidth,
          ops.h - ops.fr * 2,
          ops.fr,
          p === 0 ? -1 : 1
        );
      }
      Helpers.drawVLines(
        ctx,
        ops.fr + (ops.side < 0 ? 0 : ops.w * 0.5 - ops.fr),
        ops.fr,
        panelWidth,
        ops.h - ops.fr * 2,
        ops.fr,
        1
      );
    }
  };

  const drawDeAbrir = (
    ctx: CanvasRenderingContext2D,
    ops: Helpers.GraphicOptions,
    noFrame = false
  ) => {
    // Mosquito
    if (ops.mosquito)
      Helpers.drawMosquito(ctx, ops, mosPatternRef, ops.w / ops.panels, ops.h);
    // Modules
    Helpers.drawModules(ctx, ops);
    // Crossbars
    if (ops.crossbars) Helpers.drawCrossbars(ctx, ops, 0, ops.w, ops.h);
    // Guides
    if (ops.guide) Helpers.drawGuides(ctx, ops, ops.w);
    // Hojas
    const panelWidth = (ops.w - ops.fr * 2) / ops.panels;
    const opensTowardRight = ops.side === 1;
    let handlePanelIndex = 0;
    switch (ops.panels) {
      case 1:
        handlePanelIndex = 0;
        break;
      case 2:
        handlePanelIndex = opensTowardRight ? 1 : 0;
        break;
      case 3:
        handlePanelIndex = 1;
        break;
      case 4:
        handlePanelIndex = opensTowardRight ? 2 : 1;
        break;
    }
    let hingesSide = ops.side;
    for (let p = 0; p < ops.panels; p++) {
      if (ops.panels === 2) hingesSide = p === 0 ? -1 : 1;
      else if (ops.panels === 3) hingesSide = p === 1 ? ops.side : p - 1;
      else hingesSide = p < 2 ? -1 : 1;
      Helpers.drawFrame(
        ctx,
        ops.fr + p * panelWidth,
        ops.fr,
        panelWidth,
        ops.h - ops.fr * 2,
        ops.fr,
        ops.color,
        ops.panelAng,
        true,
        p === handlePanelIndex
          ? [Ironwork.HingesVertical, Ironwork.HandleVertical]
          : [Ironwork.HingesVertical],
        p === handlePanelIndex ? opensTowardRight : hingesSide === 1,
        ops.scale
      );
    }
    // Frame
    if (!noFrame)
      Helpers.drawFrame(
        ctx,
        0,
        0,
        ops.w,
        ops.h,
        ops.fr,
        ops.color,
        ops.frameAng
      );
    // Dashed lines
    let linesSide = ops.side;
    if (ops.panels === 1) {
      Helpers.drawHLines(
        ctx,
        ops.fr,
        ops.fr,
        ops.w - ops.fr * 2,
        ops.h - ops.fr * 2,
        ops.fr,
        linesSide
      );
    } else {
      for (let p = 0; p < ops.panels; p++) {
        if (ops.panels === 2) linesSide = p === 0 ? -1 : 1;
        else if (ops.panels === 3) linesSide = p === 1 ? ops.side : p - 1;
        else linesSide = p < 2 ? -1 : 1;
        Helpers.drawHLines(
          ctx,
          panelWidth * p + ops.fr,
          ops.fr,
          panelWidth,
          ops.h - ops.fr * 2,
          ops.fr,
          linesSide
        );
      }
    }
  };

  const drawPuertaRebatible = (
    ctx: CanvasRenderingContext2D,
    ops: Helpers.GraphicOptions
  ) => {
    // Mosquito
    if (ops.mosquito)
      Helpers.drawMosquito(ctx, ops, mosPatternRef, ops.w / ops.panels, ops.h);
    // Modules
    Helpers.drawModules(ctx, ops);
    // Guides
    if (ops.guide) Helpers.drawGuides(ctx, ops, ops.w);
    // Panels
    const panelWidth = (ops.w - ops.fr * 2) / ops.panels;
    const opensTowardRight = ops.side === 1;
    let handlePanelIndex = 0;
    switch (ops.panels) {
      case 1:
        handlePanelIndex = 0;
        break;
      case 2:
        handlePanelIndex = opensTowardRight ? 1 : 0;
        break;
      case 3:
        handlePanelIndex = 1;
        break;
      case 4:
        handlePanelIndex = opensTowardRight ? 2 : 1;
        break;
    }
    for (let p = 0; p < ops.panels; p++) {
      const x = p * panelWidth;
      if (ops.crossbars) Helpers.drawCrossbars(ctx, ops, x, panelWidth, ops.h);
      Helpers.drawFrame(
        ctx,
        ops.fr + x,
        ops.fr,
        panelWidth,
        ops.h - ops.fr,
        ops.fr,
        ops.color,
        ops.panelAng,
        true,
        p === handlePanelIndex ? [Ironwork.HandleDoor] : [],
        opensTowardRight,
        ops.scale
      );
    }
    // Frame
    Helpers.drawFrame(
      ctx,
      0,
      0,
      ops.w,
      ops.h,
      ops.fr,
      ops.color,
      ops.frameAng,
      false
    );
    // Dashed lines
    let linesSide = ops.side;
    if (ops.panels === 1) {
      Helpers.drawHLines(
        ctx,
        ops.fr,
        ops.fr,
        ops.w - ops.fr * 2,
        ops.h - ops.fr,
        ops.fr,
        linesSide
      );
    } else {
      for (let p = 0; p < ops.panels; p++) {
        if (ops.panels === 2) linesSide = p === 0 ? -1 : 1;
        else if (ops.panels === 3) linesSide = p === 1 ? ops.side : p - 1;
        else linesSide = p < 2 ? -1 : 1;
        Helpers.drawHLines(
          ctx,
          panelWidth * p + ops.fr,
          ops.fr,
          panelWidth,
          ops.h - ops.fr,
          ops.fr,
          linesSide
        );
      }
    }
  };

  const drawPuertaCorrediza = (
    ctx: CanvasRenderingContext2D,
    ops: Helpers.GraphicOptions
  ) => {
    // Mosquito
    if (ops.mosquito)
      Helpers.drawMosquito(ctx, ops, mosPatternRef, ops.w / ops.panels, ops.h);

    // Modules
    Helpers.drawModules(ctx, ops);

    const panelWidth =
      (ops.w - ops.fr * 2) / ops.panels + ops.fr * (1 - 1 / ops.panels);
    const panelHeight = ops.h - ops.fr * 2;
    // Panels
    for (let p = 0; p < ops.panels; p++) {
      const x = p * panelWidth - p * ops.fr;
      // Crossbars
      if (ops.crossbars) {
        Helpers.drawCrossbars(ctx, ops, x + ops.fr, panelWidth, ops.h);
      }
      // Panel frame
      Helpers.drawFrame(
        ctx,
        x + ops.fr,
        ops.fr,
        panelWidth,
        panelHeight,
        ops.fr,
        ops.color,
        ops.panelAng
      );
      // Arrows
      if (ops.panels !== 3) {
        const arrowRot = p % 2 === 0 ? 0 : 2;
        Helpers.drawArrow(
          ctx,
          x + panelWidth * 0.5 + p * (ops.fr - 2),
          ops.h * 0.5,
          ops.scale,
          arrowRot
        );
      } else {
        const arrowRot = p < 1 ? 0 : 2;
        Helpers.drawArrow(
          ctx,
          x + panelWidth * 0.5 + p * (ops.fr - 2),
          ops.h * 0.5,
          ops.scale,
          arrowRot
        );
        if (p === 1 && ops.panels === 3) {
          Helpers.drawArrow(
            ctx,
            x + panelWidth * 0.5 + p * (ops.fr - 2),
            ops.h * 0.5,
            ops.scale,
            0
          );
        }
      }
    }
    // Guides
    if (ops.guide) Helpers.drawGuides(ctx, ops, ops.w);
    // Frame
    Helpers.drawFrame(ctx, 0, 0, ops.w, ops.h, ops.fr, ops.color, ops.frameAng);
  };

  const drawGuillotina = (
    ctx: CanvasRenderingContext2D,
    ops: Helpers.GraphicOptions
  ) => {
    // Mosquito
    if (ops.mosquito)
      Helpers.drawMosquito(
        ctx,
        ops,
        mosPatternRef,
        ops.w,
        ops.h * 0.5,
        ops.h * 0.5
      );
    // Glass
    Helpers.drawModules(ctx, ops);

    const panelWidth = ops.w - ops.fr * 2;
    const panelHeight = (ops.h - ops.fr * 2) * 0.5 + ops.fr * 0.5;
    // Panels
    for (let p = 0; p < ops.panels; p++) {
      const y = p * panelHeight - p * ops.fr;
      // Hoja frame
      Helpers.drawFrame(
        ctx,
        ops.fr,
        y + ops.fr,
        panelWidth,
        panelHeight,
        ops.fr,
        ops.color,
        ops.panelAng
      );
      // Arrows
      let arrowRot = 1;
      if (p === ops.panels - 1) arrowRot = 3;
      Helpers.drawArrow(
        ctx,
        panelWidth * 0.5 + ops.fr,
        y + ops.h * 0.25,
        ops.scale,
        arrowRot
      );
    }
    // Guides
    if (ops.guide) Helpers.drawGuides(ctx, ops, ops.w);
    // Frame
    Helpers.drawFrame(ctx, 0, 0, ops.w, ops.h, ops.fr, ops.color, ops.frameAng);
  };

  const drawSingleCorrediza = (
    ctx: CanvasRenderingContext2D,
    ops: Helpers.GraphicOptions,
    leftSide: boolean,
    leftArrow: boolean,
    xOffset = 0
  ) => {
    const corrOps: Helpers.GraphicOptions = {
      w: ops.monorail.length,
      h: ops.h,
      fr: ops.fr,
      color: ops.color,
      modules: [],
      crossbars: [[], [], []],
      mosquito: false,
      tapajun: false,
      guide: false,
      panels: 1,
      frameAng: ops.frameAng,
      panelAng: ops.panelAng,
      side: leftSide ? -1 : 1,
      scale: ops.scale,
    };
    ctx.save();
    ctx.translate(
      (leftSide ? ops.fr : ops.w - ops.monorail.length - ops.fr) + xOffset,
      0
    );
    // Mosquito
    if (ops.mosquito)
      Helpers.drawMosquito(ctx, corrOps, mosPatternRef, corrOps.w, corrOps.h);
    const panelWidth = corrOps.w;
    const panelHeight = corrOps.h - corrOps.fr * 2;
    // Panel frame
    Helpers.drawFrame(
      ctx,
      0,
      corrOps.fr,
      panelWidth,
      panelHeight,
      corrOps.fr,
      corrOps.color,
      corrOps.panelAng,
      true,
      [Ironwork.HandleVertical],
      leftSide,
      corrOps.scale
    );
    // Arrow
    const arrowRot = leftArrow ? 0 : 2;
    Helpers.drawArrow(
      ctx,
      panelWidth * 0.5 + (leftArrow ? -ops.fr * 0.5 : ops.fr),
      corrOps.h * 0.5,
      corrOps.scale,
      arrowRot
    );
    ctx.restore();
  };

  const drawMonorriel = (
    ctx: CanvasRenderingContext2D,
    ops: Helpers.GraphicOptions
  ) => {
    Helpers.drawModules(ctx, ops);
    if (ops.monorail.side < 0 || ops.monorail.side > 1) {
      drawSingleCorrediza(ctx, ops, true, true);
    }
    if (ops.monorail.side > 0) {
      drawSingleCorrediza(ctx, ops, false, false);
    }
    if (ops.monorail.side === 0) {
      drawSingleCorrediza(
        ctx,
        ops,
        true,
        false,
        ops.w * 0.5 - ops.monorail.length - ops.fr
      );
      drawSingleCorrediza(
        ctx,
        ops,
        false,
        true,
        -ops.w * 0.5 + ops.monorail.length + ops.fr
      );
    }
    // Guides
    if (ops.guide) Helpers.drawGuides(ctx, ops, ops.w);
    // Frame
    Helpers.drawFrame(ctx, 0, 0, ops.w, ops.h, ops.fr, ops.color, ops.frameAng);
  };

  const drawMosquitero = (
    ctx: CanvasRenderingContext2D,
    ops: Helpers.GraphicOptions
  ) => {
    Helpers.drawMosquito(ctx, ops, mosPatternRef, ops.w, ops.h);
    Helpers.drawFrame(ctx, 0, 0, ops.w, ops.h, ops.fr, ops.color, ops.panelAng);
  };

  const drawPremarco = (
    ctx: CanvasRenderingContext2D,
    ops: Helpers.GraphicOptions
  ) => {
    Helpers.drawFrame(ctx, 0, 0, ops.w, ops.h, ops.fr, ops.color, ops.panelAng);
  };

  const drawCompacto = (
    ctx: CanvasRenderingContext2D,
    ops: Helpers.GraphicOptions
  ) => {
    const curtainHeight = ops.h * 0.75;
    const boxHeight = ops.h * 0.2;
    Helpers.drawCurtain(ctx, ops, 0, curtainHeight, ops.w, curtainHeight);
    Helpers.drawFrame(ctx, 0, 0, ops.w, ops.h, ops.fr, ops.color, 90, false);
    Helpers.drawRectangle(ctx, 0, 0, ops.w, boxHeight, ops.color);
  };

  // ====================================== MAIN
  const drawSingleWindow = (
    ctx: CanvasRenderingContext2D,
    props: SingleGraphicProps,
    scale: number,
    canvasWidth: number,
    canvasHeight: number,
    frameWidth: number,
    crossbarsH: number[] = [],
    crossbarsV: number[] = [],
    crossbarsMos: number[] = [],
    xOffset: number = 0,
    yOffset: number = 0
  ) => {
    const color = props.color || NO_COLOR;
    const scaledCrossbars: [number[], number[], number[]] = [
      crossbarsH.map((h) => h * scale),
      crossbarsV.map((v) => v * scale),
      crossbarsMos.map((m) => m * scale),
    ];
    const corrSpc =
      props.openingType === "copo" || props.openingType === "coab"
        ? props.openingType
        : undefined;
    const options: Helpers.GraphicOptions = {
      w: canvasWidth,
      h: canvasHeight,
      fr: frameWidth,
      color: color,
      scale: scale,
      modules: props.modules,
      mosquito: props.mosquito,
      tapajun: props.tapajun,
      frameAng: props.frameAng,
      panelAng: props.panelAng,
      side: props.side ? props.side : 0,
      guide: props.guide,
      panels: props.panels ? props.panels : 1,
      crossbars: scaledCrossbars,
      crossCut: props.crossCut,
      thinCross: props.thinCrossbar,
      modularPanel: props.modularPanel
        ? { ...props.modularPanel, length: props.modularPanel.length * scale }
        : null,
      monorail: props.monorail
        ? { side: props.monorail.side, length: props.monorail.length * scale }
        : null,
      corrSpc: corrSpc,
    };

    ctx.save();
    ctx.translate(xOffset, yOffset);
    if (props.guide) {
      ctx.translate(0, frameWidth);
    }
    if (props.tapacin)
      Helpers.drawTapacint(
        ctx,
        options,
        canvasWidth,
        canvasHeight,
        props.tapacin
      );
    if (props.tapajun) {
      ctx.translate(frameWidth, frameWidth);
      Helpers.drawFrame(
        ctx,
        -frameWidth,
        -frameWidth,
        canvasWidth + frameWidth * 2,
        canvasHeight + frameWidth + (props.threeSidedTapajun ? 0 : frameWidth),
        frameWidth,
        color,
        props.threeSidedTapajun ? 4590 : 45,
        !props.threeSidedTapajun
      );
    }
    switch (props.openingType) {
      case "corr":
      case "copo":
        drawCorrediza(ctx, options);
        break;
      case "fijo":
        drawPanoFijo(ctx, options);
        break;
      case "abri":
        drawDeAbrir(ctx, options);
        break;
      case "vluz":
        drawVentiluzOrBanderola(ctx, options, true);
        break;
      case "band":
        drawVentiluzOrBanderola(ctx, options, false);
        break;
      case "desp":
        drawDesplazable(ctx, options);
        break;
      case "osci":
        drawOscilobatiente(ctx, options);
        break;
      case "guil":
        drawGuillotina(ctx, options);
        break;
      case "mono":
        drawMonorriel(ctx, options);
        break;
      case "preb":
        drawPuertaRebatible(ctx, options);
        break;
      case "pcor":
        drawPuertaCorrediza(ctx, options);
        break;
      case "mosq":
        drawMosquitero(ctx, options);
        break;
      case "prem":
        drawPremarco(ctx, options);
        break;
      case "comp":
        drawCompacto(ctx, options);
        break;
      default:
        drawCorrediza(ctx, options);
    }
    ctx.restore();
  };

  const draw = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d", { alpha: false });

    // Calculate total measures
    let realTotalWidth = clampValue(
      props.data[0].realWidth,
      MIN_MEASURE,
      MAX_MEASURE
    );
    let realTotalHeight = clampValue(
      props.data[0].realHeight,
      MIN_MEASURE,
      MAX_MEASURE
    );
    if (props.unified) {
      props.data.forEach((part) => {
        const xw = part.xPosition + part.realWidth;
        const yh = part.yPosition + part.realHeight;
        if (xw > realTotalWidth) realTotalWidth = xw;
        if (yh > realTotalHeight) realTotalHeight = yh;
      });
    }

    let scale = DEFAULT_SCALE;
    // Scale canvas to area limit
    if (realTotalWidth > LIMIT_LENGTH || realTotalHeight > LIMIT_LENGTH) {
      scale =
        (LIMIT_LENGTH * scale) / Math.max(realTotalWidth, realTotalHeight);
    }
    // Adjust canvas size
    const canvasWidth = realTotalWidth * scale;
    const canvasHeight = realTotalHeight * scale;
    const frameWidth = FRAME_REAL_WIDTH * scale;
    let tapacinExtraLeftWidth = 0;
    let tapacinExtraRightWidth = 0;
    let tapajunExtraSize = 0;
    let guideExtraHeight = 0;
    if (!props.unified) {
      const singleWindow = props.data[0];
      if (singleWindow.tapacin) {
        if (singleWindow.tapacin === 2) {
          tapacinExtraLeftWidth = frameWidth * 1.5;
          tapacinExtraRightWidth = frameWidth * 1.5;
        } else if (singleWindow.tapacin === 1) {
          tapacinExtraRightWidth = frameWidth * 1.5;
        } else if (singleWindow.tapacin === -1) {
          tapacinExtraLeftWidth = frameWidth * 1.5;
        }
      }
      if (singleWindow.tapajun) {
        tapajunExtraSize = singleWindow.tapajun ? frameWidth : 0;
      }
      if (singleWindow.guide) {
        guideExtraHeight = singleWindow.guide ? frameWidth : 0;
      }
    }
    ctx.canvas.width =
      canvasWidth +
      SIDE_TEXT_SIZE[0] +
      tapajunExtraSize * 2 +
      tapacinExtraLeftWidth +
      tapacinExtraRightWidth;
    ctx.canvas.height =
      canvasHeight +
      SIDE_TEXT_SIZE[1] +
      tapajunExtraSize * 2 +
      guideExtraHeight;

    // White background for canvas
    Helpers.drawBg(ctx, ctx.canvas.width, ctx.canvas.height);
    ctx.translate(2, -2); // Padding so it doesn't cut off the drawing at the bottom-left corner
    ctx.save();
    ctx.translate(0, SIDE_TEXT_SIZE[1]);

    // Draw!
    props.data.forEach((part) => {
      const singleWidth =
        clampValue(part.realWidth, MIN_MEASURE, MAX_MEASURE) * scale;
      const singleHeight =
        clampValue(part.realHeight, MIN_MEASURE, MAX_MEASURE) * scale;
      const offset = [part.xPosition * scale, part.yPosition * scale];
      drawSingleWindow(
        ctx,
        part,
        scale,
        singleWidth,
        singleHeight,
        frameWidth,
        part.crossbarsH,
        part.crossbarsV,
        part.crossbarsMos,
        offset[0],
        offset[1]
      );

      // Gray cover for non-current parts
      if (props.currentId !== part.partId) {
        ctx.save();
        ctx.fillStyle = "#aaaaaa66";
        ctx.fillRect(offset[0], offset[1], singleWidth, singleHeight);
        ctx.restore();
      }
    });
    ctx.restore();

    Helpers.drawMeasures(
      ctx,
      realTotalWidth,
      realTotalHeight,
      SIDE_TEXT_SIZE[0] +
        tapajunExtraSize * 2 +
        tapacinExtraLeftWidth +
        tapacinExtraRightWidth,
      SIDE_TEXT_SIZE[1] + tapajunExtraSize * 2,
      tapacinExtraLeftWidth,
      guideExtraHeight,
      tapajunExtraSize
    );
  };

  useEffect(() => {
    draw();
  });

  return (
    <div>
      <img
        className={styles.mosquitoTex}
        src={mosqTexture}
        alt=""
        ref={mosPatternRef}
      />
      <canvas className={styles.canvas} ref={canvasRef}></canvas>
    </div>
  );
};

export default Graphic;
